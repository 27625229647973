@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#userSetting .ant-collapse-content>.ant-collapse-content-box {
  padding: 0;
}

/* #userSetting .ant-collapse > .ant-collapse-item {
  padding-left: 15px;
} */

.tableRowHighDark {
  background-color: rgb(171, 174, 191) !important;
}

.tableRowDark {
  background-color: rgb(223, 225, 234);
}

.tableRowLight {
  background-color: rgb(238, 238, 238);
}

#green-table tr:hover td {
  background-color: rgb(171, 174, 191) !important;
}

.tableClickRow {
  /* background-color:#81CDFF !important; */
  background-color: rgb(171, 174, 191) !important;
}

.setting .ant-table-small .ant-table-thead>tr>th {
  background-color: rgb(163, 165, 179);
}

#green-table>ant-table-container>tr>td {
  border-bottom: 1px solid #4CAF50;
  border-right: 1px solid #4CAF50;
}

#green-table th {
  color: #0e6511;
  border-top: 1px solid #4CAF50;
  border-bottom: 2px solid #4CAF50;
  border-right: 1px solid #4CAF50;
  background: #FFF;
  font-weight: bold;
}

#green-table td {
  border-bottom: 1px solid #4CAF50;
  border-right: 1px solid #4CAF50;
}

.FullScreenModel .ant-modal-content {
  height: calc(100vh);
  overflow-y: auto;
}

.FullScreenModel .ant-modal-header {
  display: none;
}

.FullScreenModel .ant-modal-body {
  padding: 0;
  display: flex;
  justify-content: center;
  height: calc(100vh);
  background: rgb(223, 225, 234);
}

.editForm .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-radius: 0;
}

#toolbar .ant-btn {
  border-radius: 0;
  border-color: #666;
}

#toolbar .ant-btn.ant-btn-primary {
  border-radius: 0;
  /* border-color: #1890ff; */
}

#toolbar .ant-btn.ant-btn-background-ghost.ant-btn-primary {
  border-radius: 0;
  border-color: #1890ff;
  background-color: #FFF;
}

#toolbar .ant-btn.ant-btn-primary[disabled] {
  border-radius: 0;
  border-color: #d9d9d9;
}

#toolbar .ant-btn.ant-btn-background-ghost.ant-btn-dangerous {
  border-radius: 0;
  border-color: #ff4d4f;
  background-color: #FFF;
}

.sampledStyle {
  position: absolute;
  left: -67px;
  padding: 0px;
  width: 50px;
  height: 26px;
  font-size: 12px;
  margin: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewBtn {
  padding: 0px 5px;
  height: 26px;
  line-height: 24px;
  font-size: 12px;
  /* width: 60px; */
  min-width: 60px;
  margin: 1px;
  position: absolute;
  left: 830px;
  top: 0px;
}


/* 定义keyframe动画，命名为blink */
@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 添加兼容性前缀 */
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 定义blink类*/
.blink {
  color: red;
  /* font-size:46px; */
  animation: blink 1s linear infinite;
  /* 其它浏览器兼容性前缀 */
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  -ms-animation: blink 1s linear infinite;
  -o-animation: blink 1s linear infinite;
}

.pwdInput {
  ime-mode: disabled;
}

.redText {
  color: red;
  font-weight: bold;
}