/*更改谷歌浏览器input背景*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    /*延迟背景颜色载入*/
    -webkit-transition-delay: 99999s;
    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

img {
    vertical-align: middle;
}

.img-responsive {
    width: 100%;
    height: auto;
}

.img-circle {
    border-radius: 10%;
    max-width: 110px;
}

#loginForm .ant-input-group .ant-input {
    height: 40px;
}

#loginForm .ant-input-group .ant-input-affix-wrapper {
    height: 40px;
    /* margin-left: -10px; */
    border: none;
    display: flex;
    align-items: center;
}

#loginForm .ant-input-group .ant-input-affix-wrapper-focused {
    box-shadow: none;
}
/* 
 * @author: litie 
 * @data: 2020-10-13
 */

.wrap {
    /* background: url(../../assets/images/login-bg.jpg) 100% 100%; */
    background: url(../../assets/images/login-bg2.jpeg) 100% 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background-color: #336699; */
}
#loginForm {
    border: 0;
    overflow: hidden;
}
#loginForm .ant-input-wrapper.ant-input-group {
    border: 1px solid #ddd;
    border-radius: 20px;
    overflow: hidden;
    text-indent: 10px;
}
#loginForm .ant-input-group-addon,
#loginForm .ant-input {
    border: 0 !important;
    background-color: #fff !important;
}
#loginForm .ant-col.ant-col-9 canvas {
    border-radius: 20px;
}
#loginForm .bottom input{
    border-radius: 20px;
    overflow: hidden;
}
#loginForm .bottom:focus,
#loginForm .bottom input:focus {
    outline: none;
}
#loginForm .ant-input:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}